.filterInput {
    background-image: url('../../assets/filterBackground.webp');
    background-repeat: no-repeat;
    overflow: hidden;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    color: white;
    background-color: black;
}
.filterInput__container {
    display: flex;
    flex-direction: column;
    margin: 10rem 3rem;
}
.filterInput__title {
    font-size: 2rem;
}
.filterInput__select{
    width: 7rem;
}
.filterInput__input {
    width: 8rem;
    padding: 0.5rem;
    border: 0.1rem solid rgb(64, 0, 0);
    background-color: rgb(64, 0, 0);
    color: white;
    font-size: 0.8rem;
}
.filterInput__searchButton, .filterInput__select {
    padding: 0.5rem;
    background-color: rgb(102, 44, 44);
    border: 0.1rem solid rgb(64, 0, 0);
    font-size: 0.8;
}

@media (min-width: 400px) {
.filterInput__searchButton, .filterInput__select {
    font-size: 1.2rem;
} 
.filterInput__select{
    width: 10rem;
}
.filterInput__input {
    width: 15rem;
    font-size: 1.2rem;
}
}