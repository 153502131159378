.launchCard {
    display: flex;
    flex-direction: column;
    background-color: rgb(7 15 22);
    color: white;
    min-width: 18rem;
    max-width: 25rem;
    padding: 1rem;
    margin: 1rem;
    border-radius: 1rem;
    box-shadow: rgb(37 37 37) 0.2rem 0.2rem 0.2rem;
}
.launchCard__name {
    font-weight: 400;
    font-size: 0.938rem;
}
.launchCard__table{
    background-color: rgb(7 15 22);
    color: white;
    min-width: 15rem;
    max-width: 20rem;
}
tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.05rem solid white;
}
td {
    padding: 0.5rem;
    margin-top: 0.5rem;
    font-size: 0.875rem;
    font-weight: bold;
}
.launchCard__item {
    list-style-type: disclosure-closed;
}

@media (min-Width: 700px) {
    .launchCard__table {
        min-width: 20rem;
        max-width: 30rem;
        padding: 2rem;
        margin: 2rem;
    }
}