.loading {
    background-image: url('../../assets/filterBackground.webp');
    background-repeat: no-repeat;
    overflow: hidden;
    width: 100%;
    height: 15vh;
    background-size: cover;
    background-attachment: fixed;
    color: white;
    text-align: center;
}
