.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: black;
}
.footer__logo {
    width: 85%;
    position: relative;
    left: 20px;
}
.footer__title {
    margin-bottom: 0.5rem;
}
span {
    color: gray;
}

@media (min-width: 700px) {
    .footer__logo {
        width: 25%;
    }
}