.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 2, 0);
    background-image: url('../assets/filterBackground.webp');
    background-repeat: no-repeat;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-attachment: fixed;
    color: white;
}
.notFound__title {
    font-size: 4rem;
}
a {
    font-size: 2rem;
    color: rgb(64, 0, 0);
    text-decoration: none;
}